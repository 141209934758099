import React from "react";
import { GoLocation } from "react-icons/go";
import ReCAPTCHA from "react-google-recaptcha";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { send } from "emailjs-com";
import { Modal } from "reactstrap";
import sendGif from "../Media/Images/sendGif.gif";
import contactus from "../Media/Images/Contactus.svg";

export const ContectUs = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [captcha, setCaptacha] = useState("");
  const [captchaErr, setCaptchaErr] = useState(false);
  const [modal, setModal] = useState(false);

  const submit = (data) => {
    data = { ...data, captcha: captcha };

    if (captcha === "") {
      setCaptchaErr(true);
    } else {
      send("service_uxjy11s", "template_ia3j76m", data, "90wuaN9OE1t7EaKR_")
        .then((response) => {
          console.log("SUCCESS", response.status, response.text);
          reset();
          setModal(true);
        })
        .catch((err) => {
          console.log("FAILED...", err);
        });
      setCaptchaErr(false);
      setCaptacha("");
    }
  };

  const handleCaptcha = (value) => {
    setCaptacha(value);
    setCaptchaErr(false);
  };

  return (
    <>
    <div className="fade-in-page">
      <div class="card mb-3 border-0 allMaringTop">
        <img src={contactus} class="card-img-top" alt="..." />
      </div>
      <div className="col-md-10  mx-auto mt-5 ">
        <div className="card   border-0 bg-transparent shadow">
          <div className="card  border-0  p-0 text-center  ">
            <div className="card-body SubHeadings">
              <h3 className="card-title text-secondary">
                <strong>HOW CAN WE HELP YOU!</strong>
              </h3>
              <p className="card-text text-secondary">
                <strong>
                  We would love to hear from you . Please drop us a line and we
                  will get back to you at the earliest.
                </strong>
              </p>
            </div>
          </div>

          <form
            className="row g-3 p-4  text-secondary"
            onSubmit={handleSubmit(submit)}
          >
            <div className="col-md-6 ">
              <label for="inputEmail4" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control bg-transparent text-secondary  "
                id="inputEmail4"
                {...register("name", { required: true })}
              />
              {errors.name?.type === "required" && (
                <p role="alert" className="text-danger">
                  *First name is required
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label for="inputPassword4" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control bg-transparent text-secondary"
                id="inputPassword4"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors.email?.type === "required" && (
                <p role="aler" className="text-danger">
                  *email is required
                </p>
              )}
              {errors.email?.type === "pattern" && (
                <p role="aler" className="text-danger">
                  *invalid email
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label for="inputEmail4" className="form-label">
                Company Name
              </label>
              <input
                type="text"
                className="form-control bg-transparent text-secondary"
                id="inputEmail4"
                {...register("companyName", { required: true })}
              />
              {errors.companyName?.type === "required" && (
                <p role="alert" className="text-danger">
                  *Company name is required
                </p>
              )}
            </div>
            <div className="col-md-6">
              <label for="inputPassword4" className="form-label">
                Designation
              </label>
              <input
                type="text"
                className="form-control bg-transparent text-secondary"
                id="inputPassword4"
                {...register("designation", { required: true })}
              />
              {errors.designation?.type === "required" && (
                <p role="alert" className="text-danger">
                  *Designation is required
                </p>
              )}
            </div>
            <label className="form-check-label" for="gridCheck">
              Let us know your interests
            </label>
            <div className="row mt-4 mx-auto text-secondary">
              <div className="col-md-4">
                <div className="form-check ">
                  <label className="form-check-label" for="gridCheck">
                    Staffing
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    value="Staffing"
                    {...register("interests")}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check mx-5">
                  <label className="form-check-label" for="gridCheck">
                    Consulting
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    value="Consulting"
                    {...register("interests")}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check mx-5">
                  <label className="form-check-label" for="gridCheck">
                    Solutioning
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    value="Solutioning"
                    {...register("interests")}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4 mx-auto text-secondary">
              <div className="col-md-4">
                <div className="form-check ">
                  <label className="form-check-label" for="gridCheck">
                    Application Services
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    value="Application Services"
                    {...register("interests")}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check mx-5">
                  <label className="form-check-label" for="gridCheck">
                    Engaged Services
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    value="Engaged Services"
                    {...register("interests")}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-check mx-5">
                  <label className="form-check-label" for="gridCheck">
                    Pro(T) Labs
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    value="Pro(T) Labs"
                    {...register("interests")}
                  />
                </div>
              </div>
            </div>

            <div className="col-12 mt-4 text-secondary">
              <label for="exampleFormControlTextarea1" className="form-label">
                Message
              </label>
              <textarea
                className="form-control bg-transparent text-secondary"
                id="exampleFormControlTextarea1"
                rows="4"
                {...register("message")}
              ></textarea>
            </div>
            <div className="col-12">
              <ReCAPTCHA
                sitekey="6LcXgXcjAAAAAPWRdeP8b8nScXy2_4wrl1SvNuSQ"
                onChange={handleCaptcha}
              />
              {captchaErr ? (
                <p role="aler" className="text-danger">
                  *Please complete the captcha
                </p>
              ) : (
                <></>
              )}
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-warning">
                SEND MESSAGE
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="col-md-10 mx-auto">
        <div className="card bg-transparent mt-5 border-0 text-center">
          <div className="card-body">
            <div className="iconColor mb-3">
              <GoLocation size="54" />
            </div>
            <h5 className="SubHeadingsBlack">
              <strong>Our Address</strong>
            </h5>
            <h2 className="card-title InnerMianHaddings">
              PROTECONS SOLUTIONS PVT. LTD.
            </h2>
          </div>
        </div>
        <div className="row ">
          <div className="col-md-6  mt-4">
            <div className="card Formbg bg-transparent border-0 p-0 ">
              {/* <div className='card-body p-0'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3509.9300378998355!2d79.45784471408945!3d28.39118100171613!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfcb1d77f2403%3A0xc5c3a3e445b2f3d1!2sV%20P%20Softwares%20Private%20Limited!5e0!3m2!1sen!2sin!4v1663653944335!5m2!1sen!2sin" width="100%" height='300' allowfullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div> */}
            </div>
            <div className="mt-4 text-center Sub-Sub-Hadding">
              <p>
              VO-363 Block L, Embassy Tech Village, Outer Ring Road,
              </p>
              <p>Bellandur Bengaluru, Bengaluru, Urban Karnataka- 560103</p>
            </div>
          </div>
          <div className="col-md-6  mt-4">
            <div className="card Formbg bg-transparent border-0 p-0 ">
              {/* <div className='card-body p-0'>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.724016180778!2d77.63266540000001!3d12.9254514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14604648e0c3%3A0xb188aeb2382e3c44!2sPROTECONS%20SOLUTIONS%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1670318646540!5m2!1sen!2sin" width="100%" height="300" allowfullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div> */}
            </div>
            <div className="mt-4 text-center Sub-Sub-Hadding">
              <p>Swastik Towers, CP-17/18,Mahanagar</p>
              <p>Pilibhit Bypass Road,Bareilly UP - 243005 India</p>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="position-absolute top-50 start-50 translate-middle"
        size={200}
        isOpen={modal}
        toggle={() => setModal(!modal)}
      >
        <div className="card bg-transparent border-0">
          <div className="card-body">
            <img src={sendGif} alt="Sendgif" width={200} height={150} />
          </div>
        </div>
      </Modal>
    </div>
    </>
  );
};
